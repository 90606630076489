import { AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import { COLORS } from "../assets/colors";
import { getServerMgr } from "../managers/ServerMgr";
import { useEffect, useRef, useState } from "react";
import { useMapitoStore } from "../zustand/MapitoStore";
import GenericButton from "../common/GenericButton";
import NotInserted from "../common/NotInserted";
import { getUtils } from "../managers/Utils";
import AppoRow from "../ui/markerList/AppoRow";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import "./MarkerInfo.css"
import { Button } from "@mui/material";

export default function MarkerInfo(props) {

    //STORE
    const setModal = useMapitoStore((state) => state.setModal)
    const updateData = useMapitoStore((state) => state.updateData)
    const setShowAddAppoModal = useMapitoStore((state) => state.setShowAddAppoModal)

    const [idCount, setIdCount] = useState(0)

    const [appointmentFormList, setAddAppointmentFormList] = useState([])

    useEffect(() => {
        setAddAppointmentFormList([])
    }, [props.isClosing])

    useEffect(() => {
        setAddAppointmentFormList([])
    }, [props.info?.id])

    const onClickDeleteMarker = (e) => {
        setModal("delete", {markerInfo: props.info, closeSideSlider: props.closeSideSlider})
    }    

    const addAppointmentForm = () => {
        setAddAppointmentFormList((prevState) => ([...prevState, {id: idCount, timestamp: "", date: "", time:"", type: 1, notes: ""}]))
        setIdCount((prevState) => (prevState + 1))
    }

    const changeDate = (id, value) => {
        let appoIndex = appointmentFormList.findIndex((elem) => (elem.id === id))
        let appoListCopy = Object.assign({}, appointmentFormList[appoIndex])
        appoListCopy.date = value
        const newAppoList = appointmentFormList.slice()
        newAppoList[appoIndex] = appoListCopy
        setAddAppointmentFormList(newAppoList)
    }

    const changeTime = (id, value) => {
        let appoIndex = appointmentFormList.findIndex((elem) => (elem.id === id))
        let appoListCopy = Object.assign({}, appointmentFormList[appoIndex])
        appoListCopy.time = value
        const newAppoList = appointmentFormList.slice()
        newAppoList[appoIndex] = appoListCopy
        setAddAppointmentFormList(newAppoList)
    }

    const changeType = (id, value) => {
        let appoIndex = appointmentFormList.findIndex((elem) => (elem.id === id))
        let appoListCopy = Object.assign({}, appointmentFormList[appoIndex])
        appoListCopy.type = value
        const newAppoList = appointmentFormList.slice()
        newAppoList[appoIndex] = appoListCopy
        setAddAppointmentFormList(newAppoList)
    }

    const changeNotes = (id, value) => {
        let appoIndex = appointmentFormList.findIndex((elem) => (elem.id === id))
        let appoListCopy = Object.assign({}, appointmentFormList[appoIndex])
        appoListCopy.notes = value
        const newAppoList = appointmentFormList.slice()
        newAppoList[appoIndex] = appoListCopy
        setAddAppointmentFormList(newAppoList)
    }

    const addAppointment = async () => {
        let timestampAppointmentsList = addTimeStamp(appointmentFormList)
        await getServerMgr().addAppointments({markerId: props.info?.id, appointmentsList: timestampAppointmentsList}, updateData)
        setAddAppointmentFormList([])
        updateData()
    }

    const addTimeStamp = (appoList) => {
        let returnList = []
        appoList.forEach((item) => {
            let shallowCopyItem = {...item}
            if(item.date) {
                let calculatedTimestamp = new Date(item.date + " " + (item.time ? item.time : "23:59:59")).valueOf() / 1000;
                shallowCopyItem["timestamp"] = calculatedTimestamp;
            } else {                
                shallowCopyItem["timestamp"] = null;
            }
            returnList.push(shallowCopyItem)
        });
        return returnList
    }

    return(
        <div>
            <h3>{props.info?.address ? props.info?.address : <NotInserted/>}</h3>
            <div style={{display: props.info?.marker_notes ? "block" : "none"}}>{"Note: " + props.info?.marker_notes}</div>
            <div>{props.info?.needWarning ? "NECESSITA AVVISO" : "NON NECESSITA AVVISO"}</div>
            <h5 style={{margin: "20px 0"}}>Amministratore</h5>
            <AdminRow label={"Cognome"} value={props.info.administrator?.surname ? props.info.administrator?.surname : <NotInserted/>}></AdminRow>
            <AdminRow label={"Nome"} value={props.info.administrator?.name ? props.info.administrator?.name : <NotInserted/>}></AdminRow>
            <AdminRow label={"Telefono"} value={props.info.administrator?.telephone ? props.info.administrator?.telephone : <NotInserted/>}></AdminRow>
            <AdminRow label={"Email"} value={props.info.administrator?.email ? props.info.administrator?.email : <NotInserted/>}></AdminRow>
            <div className='flexContainer' style={{margin: "20px 0 30px 0"}}>
                <h5>Interventi</h5>          
                <div style={{width: "20px"}}></div>
                <div style={{display: "flex", flexWrap: "wrap", alignContent: "center"}}>
                    {/* <div className='markerInfo_addAppoButton' onClick={() => {setShowAddAppoModal(true, {markerId: props.info?.id})}}>
                        <AddCircleIcon/>
                        <div className="markerInfo_appoInfo_container">Aggiungi</div>
                    </div> */}
                    <Button size="small" variant="contained" startIcon={<AddCircleIcon />} onClick={() => {setShowAddAppoModal(true, {markerId: props.info?.id})}} style={{height: "30px"}}>
                        Aggiungi
                    </Button>
                </div>
            </div>
            {
                props.info?.appointments.length !== 0
                ?
                props.info?.appointments.map((item) => (
                    <AppoRow key={item.appointment_id} appo={item}></AppoRow>
                ))
                :
                <div>Nessun intervento</div>
            }
        </div>
    )

    return(
        // <div style={{width: "calc(100% - 30px)", height: "100%", padding: " 15px 15px 15px 15px"}}>
        <div style={{width: "100%", maxHeight: "100%", display: "flex", flexDirection: "column", alignItems: "start"}}>
            {/* <button onClick={onClickDeleteMarker}>ELIMINA MARKER</button>      */}
            <GenericButton
                redStyling
                onClick={onClickDeleteMarker}
                buttonText="Elimina Marker"
            >
            </GenericButton>

            <fieldset className="fieldset_style">
                <legend className="legend_style">CONDOMINIO</legend>
                <div className="short_styling" style={{marginLeft: "5px"}}>
                    <div className="label_style">INDIRIZZO:</div>
                    <div className="content_info">{props.info?.address ? props.info?.address : <NotInserted/>}</div>

                    <div className="label_style">NOTE CONDOMINIO:</div>
                    <div className="content_info">{props.info?.marker_notes ? props.info?.marker_notes : <NotInserted/>}</div>

                    <div className="label_style">AVVISO:</div>
                <div className="content_info">{props.info?.needWarning ? "NECESSITA AVVISO" : "NON NECESSITA AVVISO"}</div>
                </div>
            </fieldset>

            <fieldset className="fieldset_style">
                <legend className="legend_style">AMMINISTRATORE</legend>
                <AdministratorInfo adminInfo={props.info?.administrator}></AdministratorInfo>
            </fieldset>

            <fieldset className="fieldset_style">
                <legend className="legend_style">INTERVENTI</legend>
                {   
                props.info?.appointments?.length
                ?
                props.info?.appointments.map((item) => (
                    <AppointmentInfo appointment={item}></AppointmentInfo>
                ))
                :
                <div style={{fontStyle: "italic"}}>Nessun intervento</div>
            } 
            </fieldset>
              
            <div>
                <GenericButton
                    onClick={addAppointmentForm}
                    buttonText={"Aggiungi Intervento"}
                >
                </GenericButton>
            </div>

            {appointmentFormList.length !== 0 &&
                <GenericButton
                    greenStyling
                    onClick={addAppointment}
                    buttonText={"Salva Interventi"}
                >
                </GenericButton>
            }
            
            {
                appointmentFormList.map((item) => (
                    <>
                        <NewAppointmentForm key={item.id} appoInfo={item} changeDate={changeDate} changeTime={changeTime} changeType={changeType} changeNotes={changeNotes} handleDropdownChange={changeType}></NewAppointmentForm>
                    </>
                ))
            } 
            
            
        </div>
    )
}

const AdminRow = ({label, value}) => {
    return(
        <>
            <hr/>
            <div className="flexContainer">
                <div style={{width: "100px"}}>{label}</div>
                <div>{value}</div>
            </div>
        </>
    )
}

const AdministratorInfo = (props) => {

    return(
        <div className="short_styling" style={{marginLeft: "5px"}}>
            <div /*className="flexContainer"*/>
                <div className="label_style">COGNOME: </div>
                <div className="content_info">{props.adminInfo?.surname ? props.adminInfo?.surname : <NotInserted/>}</div>
                {/* <div className="content_info"></div> */}
                {/* {props.adminInfo?.surname} */}
            </div>
            <div /*className="flexContainer"*/>
                <div className="label_style">NOME: </div>
                <div className="content_info">{props.adminInfo?.name ? props.adminInfo?.name : <NotInserted/>}</div>
                
            </div>
            <div /*className="flexContainer"*/>
                <div className="label_style">TELEFONO: </div>
                <div className="content_info">{props.adminInfo?.telephone ? props.adminInfo?.telephone : <NotInserted/>}</div>
                
            </div>
            <div /*className="flexContainer"*/>
                <div className="label_style">EMAIL: </div>
                <div className="content_info">{props.adminInfo?.email ? props.adminInfo?.email : <NotInserted/>}</div>
                
            </div>
        </div>
    )
}

const AppointmentInfo = (props) => {

    const Utils = getUtils()

    //STORE
    const setModal = useMapitoStore((state) => state.setModal)
    
    return(
        <div style={{marginLeft: "5px", marginBottom: "10px", borderBottom: "2px solid white", paddingBottom: "10px"}}>
            <div /*className="flexContainer"*/>
                <div className="label_style">DATA:</div>
                <div className="content_info">{(props.appointment?.date !== "0000-00-00" && props.appointment?.date) ? Utils.parseDate(props.appointment?.date) : <NotInserted/>}</div>
            </div>  
            <div /*className="flexContainer"*/>
                <div className="label_style">TIPO:</div>
                <div className="content_info">{props.appointment?.type_name ? props.appointment?.type_name : <NotInserted/>}</div>
            </div>  
            <div /*className="flexContainer"*/>
                <div className="label_style">STATO DEL LAVORO:</div>
                <div>{props.appointment?.completed ? <div style={{padding: "0"}} className="markerRow_completed_label markerRow_completed_label_completed"></div> : <div style={{padding: "0"}} className="markerRow_completed_label markerRow_completed_label_notCompleted"></div>}</div>
            </div>  
            <div /*className="flexContainer"*/ style={{display: props.appointment?.completed ? "block" : "none"}}>
                <div className="label_style">DATA ESECUZIONE:</div>
                <div className="content_info">{(props.appointment?.completion_date !== "0000-00-00" && props.appointment?.completion_date) && props.appointment?.completion_date !== null ? Utils.parseDate(props.appointment?.completion_date) : <NotInserted/>}</div>
            </div>  
            <div /*className="flexContainer"*/>
                <div className="label_style">NOTE:</div>
                <div className="content_info">{props.appointment?.appointment_notes ? props.appointment?.appointment_notes : <NotInserted/>}</div>
            </div>  
            <GenericButton
                buttonText={"Completa Lavoro"}
                onClick={() => {setModal("complete", {appoInfo: props.appointment})}}
                shortStyling
            >
            </GenericButton>        
        </div>
    )
}

const NewAppointmentForm = (props) => {

    //STORE    
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)

    // const [date, setDate] = useState("")
    // const [type, setType] = useState("")
    const dateInputRef = useRef(null);
    const timeInputRef = useRef(null);

    return(
        <div style={{marginLeft: "10px", marginBottom: "10px"}}>
            {/* <div>--------------------</div> */}
            <hr></hr>
            <div className="label_style">DATA:</div>
            <input className="input_style" type={"date"} value={props.appoInfo.date} onChange={(e) => props.changeDate(props.appoInfo.id, e.target.value)} ref={dateInputRef}></input>
            <div className="label_style">ORARIO:</div>
            <input className="input_style" type={"time"} value={props.appoInfo.time} onChange={(e) => props.changeTime(props.appoInfo.id, e.target.value)} ref={timeInputRef}></input>
            <div className="label_style">TIPO:</div>
            {/* <input value={props.appoInfo.type} onChange={(e) => props.changeType(props.appoInfo.id, e.target.value)}></input>    */}

            <select className="select_style" onChange={(e) => props.handleDropdownChange(props.appoInfo.id, appointmentTypes[e.target.selectedIndex].id)}>
                {/* <option value="">Select an option</option> */}
                {appointmentTypes.map((option) => (
                <option key={option.id} value={option.name}>
                    {option.name}
                </option>
                ))}
            </select>

            <div className="label_style">NOTE</div>
            <textarea className="input_style" value={props.appoInfo.notes} onChange={(e) => props.changeNotes(props.appoInfo.id, e.target.value)}></textarea>         
        </div>
    )
}