import { useRef, useState } from "react";
import NotInserted from "../../common/NotInserted";
import "./AppoRow.css"
import SwipeToDelete from 'react-swipe-to-delete-ios'
import ModalComponent from "../modal/ModalComponent";

import {ICONS} from "../../assets/icons"
import { IMGNOURL } from "../../assets/imgNoUrl";
import { Divider } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMapitoStore } from "../../zustand/MapitoStore";
import { getServerMgr } from "../../managers/ServerMgr";
import { getUtils } from "../../managers/Utils";

export default function AppoRow({appo, showMarker}) {

    const setShowCompleteModal = useMapitoStore((state) => state.setShowCompleteModal)
    const setShowDeleteAppoModal = useMapitoStore((state) => state.setShowDeleteAppoModal)
    const setShowEditAppoModal = useMapitoStore((state) => state.setShowEditAppoModal)
    const updateData = useMapitoStore((state) => state.updateData)

    const [showButton, setShowButton] = useState(false)

    const utils = getUtils()

    return (
        <>
        <div style={{marginBottom: "20px"}} className="hide">  
            <SwipeToDelete
                onDelete={updateData}   
                deleteText="Elimina" // default
                height={showMarker ? 69 : 40} // default  
                onDeleteConfirm={(onSuccess, onCancel) => {
                    setShowDeleteAppoModal(true, {...appo, onConfirm: onSuccess, onCancel: onCancel})
                }}                
            >   
                <div style={{backgroundColor: "white"}}>   
                    <div className="appoRow_container">
                        <div className="flexContainer">
                            <div 
                                className={appo.completed != 0 ? "appoRow_buttonCompleted" : "appoRow_buttonNotCompleted"} 
                                style={{backgroundImage: appo.completed != 0 ? ICONS.appoCompleteDark : ICONS.appoCompleteDarkDisabled}}
                                onClick={() => setShowCompleteModal(true, appo)}
                            >
                            </div>
                            <div onClick={() => {setShowEditAppoModal(true, appo)}} className="appoTable_textLabel_doubleLine">
                                <div style={{fontWeight: "bold"}}>{appo.type_name}</div>
                                <div className="flexContainer" style={{fontSize: 12, fontWeight: "bold", color: "#99ADB0"}}>
                                    <div>{appo.date && appo.date !== "0000-00-00" ? (utils.parseDate(appo.date)) : ""}</div>
                                    <div>{appo.date && appo.date !== "0000-00-00" && appo.time ? <>&nbsp;|&nbsp;</> : ""}</div>
                                    <div>{appo.time}</div>
                                </div>
                            </div>
                        </div>
                        <div className="appoRow_completionDate" onClick={() => {setShowEditAppoModal(true, appo)}}>
                            <div>{appo.completed ? utils.parseDate(appo.completion_date) : <NotInserted/>}</div>
                        </div>
                    </div>
                    {
                        showMarker
                        ?
                        <div><LocationOnIcon />{appo.address}</div>     
                        :
                        null
                    }
                </div>
            </SwipeToDelete> 

            <div className="appoRow_notesContainer" style={{display: appo.appointment_notes ? "block" : "none"}} onClick={() => {setShowEditAppoModal(true, appo)}}>
                {"NOTE: " + appo.appointment_notes}
            </div>
            <hr style={{borderColor: "black"}}/>   
            {/* {
                showMarker
                ?
                <hr style={{borderColor: "black"}}/>    
                :
                null
            }        */}
            {/* <div style={{display: showButton ? "flex" : "none"}}>
                <div style={{width: "50%", backgroundColor: "#5dad6e", borderRadius: "6px"}}>MODIFICA</div>
                <div style={{width: "50%", backgroundColor: "#f15151", borderRadius: "6px"}}>CANCELLA</div>
            </div> */}
        </div>
        
        {/* //DESKTOP */}

        <div style={{marginBottom: "20px"}} className="hide_mobile">  
            <div style={{backgroundColor: "white"}}>   
                <div className="appoRow_container">
                    <div className="flexContainer">
                        <div 
                            className={appo.completed != 0 ? "appoRow_buttonCompleted" : "appoRow_buttonNotCompleted"} 
                            style={{backgroundImage: appo.completed != 0 ? ICONS.appoCompleteDark : ICONS.appoCompleteDarkDisabled}}
                            onClick={() => setShowCompleteModal(true, appo)}
                        >
                        </div>
                        <div className="appoTable_textLabel_doubleLine">
                            <div style={{fontWeight: "bold"}}>{appo.type_name}</div>
                            <div className="flexContainer" style={{fontSize: 12, fontWeight: "bold", color: "#99ADB0"}}>
                                <div>{appo.date && appo.date !== "0000-00-00" ? (utils.parseDate(appo.date)) : ""}</div>
                                <div>{appo.date && appo.date !== "0000-00-00" && appo.time ? <>&nbsp;|&nbsp;</> : ""}</div>
                                <div>{appo.time}</div>
                            </div>
                        </div>
                    </div>
                    <div className="appoRow_completionDate">
                        <div>{appo.completed ? utils.parseDate(appo.completion_date) : <NotInserted/>}</div>
                    </div>
                    <div className='appoRow_button_container'>
                        <div className='appoRow_button_edit' style={{backgroundImage: ICONS.edit}} onClick={() => {setShowEditAppoModal(true, appo)}}></div>
                        <div className='appoRow_button_delete' style={{backgroundImage: ICONS.delete}} onClick={() => {setShowDeleteAppoModal(true, appo)}}></div>
                    </div>
                </div>
                {
                    showMarker
                    ?
                    <div><LocationOnIcon />{appo.address}</div>     
                    :
                    null
                }
            </div>
            <div className="appoRow_notesContainer" style={{display: appo.appointment_notes ? "block" : "none"}} onClick={() => {setShowEditAppoModal(true, appo)}}>
                {"NOTE: " + appo.appointment_notes}
            </div>
            <hr style={{borderColor: "black"}}/>   
            {/* {
                showMarker
                ?
                <hr style={{borderColor: "black"}}/>    
                :
                null
            }        */}
            {/* <div style={{display: showButton ? "flex" : "none"}}>
                <div style={{width: "50%", backgroundColor: "#5dad6e", borderRadius: "6px"}}>MODIFICA</div>
                <div style={{width: "50%", backgroundColor: "#f15151", borderRadius: "6px"}}>CANCELLA</div>
            </div> */}
        </div>
        </>
        
    )
}