import { MenuItem, TextField } from "@mui/material";
import BasicSelect from "../ui/input/select/BasicSelect";
import { useMapitoStore } from "../zustand/MapitoStore";
import "./Sorter.css"

export default function AppoSorter({}) {

    const appoSortBy = useMapitoStore((state) => state.appoSortBy)
    const setAppoSorter = useMapitoStore((state) => state.setAppoSorter)
    const appoSortingList = useMapitoStore((state) => state.appoSortingList)

    const appoSortingType = useMapitoStore((state) => state.appoSortingType)
    const setAppoSortingType = useMapitoStore((state) => state.setAppoSortingType)
    const sortingTypeList = useMapitoStore((state) => state.sortingTypeList)
    
    return(
        <>
            <BasicSelect label={"Criterio"} value={appoSortBy} onChange={setAppoSorter} valuesIdList={appoSortingList} containerClassName={"sorter_container"} style={{marginBottom: "20px"}}/>
            <BasicSelect label={"Ordine"} value={appoSortingType} onChange={setAppoSortingType} valuesIdList={sortingTypeList} containerClassName={"sorter_container"}/>
        </>
    )
}