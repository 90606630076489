import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMapitoStore } from '../../zustand/MapitoStore';
import EditMarkerModal from '../../markerList/EditMarkerModal';
import DeleteMarkerModal from '../../markerList/DeleteMarkerModal';
import CompleteAppoModal from '../../modal/CompleteAppoModal';
import DeleteAppoModal from '../../modal/DeleteAppoModal';
import EditAppoModal from '../../modal/EditAppoModal';
import { getServerMgr } from '../../managers/ServerMgr';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { getUtils } from '../../managers/Utils';
import { Select, TextField } from '@mui/material';
import BasicSelect from '../input/select/BasicSelect';

import "./ModalAddAppo.css"
import CustomCarousel from '../carousel/CustomCarousel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export default function ModalEditAppo(props) {
    const show = useMapitoStore((state) => state.showEditAppoModal)
    const setShow = useMapitoStore((state) => state.setShowEditAppoModal)
    const modalInfo = useMapitoStore((state) => state.editAppoModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)

    //STATE
    const [type, setType] = useState(1)
    const [date, setDate] = useState(null)
    const [time, setTime] = useState(null)
    const [notes, setNotes] = useState("")

    //USEEFFECT
    useEffect(() => {
        if(show) {
            setType(modalInfo.type_id)
            setDate(modalInfo.date)
            setTime(modalInfo.time)
            setNotes(modalInfo.appointment_notes)
        }
    }, [show])

    const handleClose = () => setShow(false, {});
    const handleShow = () => setShow(true);

    const onClose = () => {   
        handleClose()
    }

    const onConfirm = async (id) => {
        let newTimestamp = getUtils().addTimeStamp(date, time)
        await getServerMgr().editAppo({appoId: modalInfo.appointment_id, type: type, date: date, time: time, notes: notes, timestamp: newTimestamp}, () => updateData(handleClose))
        // setAddAppointmentFormList([])
        // updateData()
    }

    const parseAppoTypeList = (list) => {
        let parsedList = []
        list.forEach((item) => (
            parsedList.push({value: item.id, label: item.name})
        ))
        return parsedList
    }

    return (
        <>
        <Modal 
            show={show} 
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {"Modifica intervento"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div>
                <h6 style={{marginBottom: "20px"}}>{"Modifica intervento:"}</h6>
                <div className='modalAddAppo_bodyContainer'>
                    <div style={{width: "80%"}}>
                            {/* <DatePicker label={"Data esecuzione"} value={appoDate} onChange={onChangeDate} />
                            <TimePicker label={"Data esecuzione"} value={appoTime} onChange={onChangeTime} /> */}
                        <div className="modalAddAppo_infoContainer" > 
                            <div className="modalAddAppo_selectContainer"> 
                                <BasicSelect label={"Tipo intervento"} value={type} onChange={(e) => setType(Number(e.target.value))} valuesIdList={parseAppoTypeList(appointmentTypes)} containerClassName={"sorter_container"} minWidth={190}/>                                
                            </div>                   
                        <div className='modalAddAppo_dateTimeContainer'>
                                <TextField label={"Data"} type='date' InputLabelProps={{ shrink: true }} placeholder='Data' value={date} onChange={(e) => setDate(e.target.value)}/>
                                <TextField label={"Orario"} type='time' InputLabelProps={{ shrink: true }} placeholder='Orario' value={time} onChange={(e) => setTime(e.target.value)}/>
                            </div>
                            {/* <div><>Data esecuzione: &nbsp;</></div> */}
                            {/* <TextField label={"Data esecuzione"} type='date' shrink value={appoDate} onChange={onChangeDate}/>
                            <DatePicker label={"Data esecuzione"} value={appoDate} onChange={onChangeDate} />
                            <TimePicker label={"Data esecuzione"} value={appoTime} onChange={onChangeTime} /> */}
                            {/* <input type="date" value={completeDate} onChange={(e) => setCompleteDate(e.target.value)}></input> */}
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>                        
                            <TextField
                                style={{width: "100%", marginLeft: "8px"}}
                                id="outlined-multiline-static"
                                label="Note"
                                multiline
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </div>                       
                        <div style={{height: "60px"}}></div>
                    </div>
                </div>
            </div>    
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onConfirm}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Annulla"}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}



const NewAppointmentForm = ({appoInfo, changeDate, changeTime, changeType, changeNotes/*, handleDropdownChange={changeType}*/}) => {

    //STORE    
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)    

    //STATE
    // const [appoType, setAppoType] = useState(1)
    // const [appoDate, setAppoDate] = useState(null)
    // const [appoTime, setAppoTime] = useState(null)
    // const [appoNote, setAppoNote] = useState("")

    //FUNCTION
    // const onChangeDate = (e) => {
    //     // let dateParsed = e.$y.toString() + "-" + (e.$M > 10 ? e.$M : "0"+e.$M) + "-" + (e.$D > 10 ? e.$D : "0"+e.$D)
    //     // setAppoDate(e.$d)
    //     setAppoDate(e.target.value)
    // }

    // const onChangeTime = (e) => {
    //     setAppoTime(e.target.value)
    // }

    const parseAppoTypeList = (list) => {
        let parsedList = []
        list.forEach((item) => (
            parsedList.push({value: item.id, label: item.name})
        ))
        return parsedList
    }

    // const onChangeType = (e) => {
    //     setAppoType(Number(e.target.value))
    // }

    // const onChangeNote = (e) => {
    //     setAppoNote(e.target.value)
    // }

    return(
        // <div style={{marginLeft: "10px", marginBottom: "10px"}}>
        //     {/* <div>--------------------</div> */}
        //     <hr></hr>
        //     <div className="label_style">DATA:</div>
        //     <input className="input_style" type={"date"} value={props.appoInfo.date} onChange={(e) => props.changeDate(props.appoInfo.id, e.target.value)} ref={dateInputRef}></input>
        //     <div className="label_style">ORARIO:</div>
        //     <input className="input_style" type={"time"} value={props.appoInfo.time} onChange={(e) => props.changeTime(props.appoInfo.id, e.target.value)} ref={timeInputRef}></input>
        //     <div className="label_style">TIPO:</div>
        //     {/* <input value={props.appoInfo.type} onChange={(e) => props.changeType(props.appoInfo.id, e.target.value)}></input>    */}

        //     <select className="select_style" onChange={(e) => props.handleDropdownChange(props.appoInfo.id, appointmentTypes[e.target.selectedIndex].id)}>
        //         {/* <option value="">Select an option</option> */}
        //         {appointmentTypes.map((option) => (
        //         <option key={option.id} value={option.name}>
        //             {option.name}
        //         </option>
        //         ))}
        //     </select>

        //     <div className="label_style">NOTE</div>
        //     <textarea className="input_style" value={props.appoInfo.notes} onChange={(e) => props.changeNotes(props.appoInfo.id, e.target.value)}></textarea>         
        // </div>
        <div>
            <h6 style={{marginBottom: "20px"}}>{"Aggiungere intervento:"}</h6>
            <div className='modalAddAppo_bodyContainer'>
                <div style={{width: "80%"}}>
                        {/* <DatePicker label={"Data esecuzione"} value={appoDate} onChange={onChangeDate} />
                        <TimePicker label={"Data esecuzione"} value={appoTime} onChange={onChangeTime} /> */}
                    <div className="modalAddAppo_infoContainer" > 
                        <div className="modalAddAppo_selectContainer"> 
                            <BasicSelect label={"Tipo intervento"} value={appoInfo.type} onChange={(e) => changeType(appoInfo.id, Number(e.target.value))} valuesIdList={parseAppoTypeList(appointmentTypes)} containerClassName={"sorter_container"} minWidth={190}/>                                
                        </div>                   
                    <div className='modalAddAppo_dateTimeContainer'>
                            <TextField label={"Data"} type='date' InputLabelProps={{ shrink: true }} value={appoInfo.date} onChange={(e) => changeDate(appoInfo.id, e.target.value)}/>
                            <TextField label={"Orario"} type='time' InputLabelProps={{ shrink: true }} value={appoInfo.time} onChange={(e) => changeTime(appoInfo.id, e.target.value)}/>
                        </div>
                        {/* <div><>Data esecuzione: &nbsp;</></div> */}
                        {/* <TextField label={"Data esecuzione"} type='date' shrink value={appoDate} onChange={onChangeDate}/>
                        <DatePicker label={"Data esecuzione"} value={appoDate} onChange={onChangeDate} />
                        <TimePicker label={"Data esecuzione"} value={appoTime} onChange={onChangeTime} /> */}
                        {/* <input type="date" value={completeDate} onChange={(e) => setCompleteDate(e.target.value)}></input> */}
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>                        
                        <TextField
                            style={{width: "100%", marginLeft: "8px"}}
                            id="outlined-multiline-static"
                            label="Note"
                            multiline
                            value={appoInfo.notes}
                            onChange={(e) => changeNotes(appoInfo.id, e.target.value)}
                        />
                    </div>                       
                    <div style={{height: "60px"}}></div>
                </div>
            </div>
        </div>    
    )
}