import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMapitoStore } from '../../zustand/MapitoStore';
import EditMarkerModal from '../../markerList/EditMarkerModal';
import DeleteMarkerModal from '../../markerList/DeleteMarkerModal';
import CompleteAppoModal from '../../modal/CompleteAppoModal';
import DeleteAppoModal from '../../modal/DeleteAppoModal';
import EditAppoModal from '../../modal/EditAppoModal';
import { getServerMgr } from '../../managers/ServerMgr';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { getUtils } from '../../managers/Utils';
import { Select, TextField } from '@mui/material';
import BasicSelect from '../input/select/BasicSelect';

import "./ModalAddAppo.css"
import CustomCarousel from '../carousel/CustomCarousel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export default function ModalAddAppo(props) {
    const show = useMapitoStore((state) => state.showAddAppoModal)
    const setShow = useMapitoStore((state) => state.setShowAddAppoModal)
    const modalInfo = useMapitoStore((state) => state.addAppoModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const [appoFormList, setAppoFormList] = useState([<NewAppointmentForm/>])
    const [activeIndex, setActiveIndex] = useState(0)

//________________________
const [appoFormList, setAppoFormList] = useState([{id: 1, timestamp: "", date: "", time:"", type: 1, notes: ""}])
const [idCount, setIdCount] = useState(2)
const [numAddAppoForm, setNumAddAppoForm] = useState("1")

// useEffect(() => {
//     setAppoFormList([])
// }, [props.isClosing])

// useEffect(() => {
//     setAppoFormList([])
// }, [props.info?.id])

// const onClickDeleteMarker = (e) => {
//     setModal("delete", {markerInfo: props.info, closeSideSlider: props.closeSideSlider})
// }    

const newAppoForm = () => {
    let currentIndex = idCount
    let newFormArr = []
    if(Number(numAddAppoForm) > 0) {
        while(currentIndex < (idCount + Number(numAddAppoForm))) {          
            newFormArr.push({id: currentIndex, timestamp: "", date: "", time:"", type: 1, notes: ""})
            currentIndex++
        }              
        setAppoFormList((prevState) => ([...prevState, ...newFormArr]))
        setIdCount(currentIndex)
        setActiveIndex(currentIndex - 2)
    }
}

const changeDate = (id, value) => {
    let appoIndex = appoFormList.findIndex((elem) => (elem.id === id))
    let appoListCopy = Object.assign({}, appoFormList[appoIndex])
    appoListCopy.date = value
    const newAppoList = appoFormList.slice()
    newAppoList[appoIndex] = appoListCopy
    setAppoFormList(newAppoList)
}

const changeTime = (id, value) => {
    let appoIndex = appoFormList.findIndex((elem) => (elem.id === id))
    let appoListCopy = Object.assign({}, appoFormList[appoIndex])
    appoListCopy.time = value
    const newAppoList = appoFormList.slice()
    newAppoList[appoIndex] = appoListCopy
    setAppoFormList(newAppoList)
}

const changeType = (id, value) => {
    let appoIndex = appoFormList.findIndex((elem) => (elem.id === id))
    let appoListCopy = Object.assign({}, appoFormList[appoIndex])
    appoListCopy.type = value
    const newAppoList = appoFormList.slice()
    newAppoList[appoIndex] = appoListCopy
    setAppoFormList(newAppoList)
}

const changeNotes = (id, value) => {
    let appoIndex = appoFormList.findIndex((elem) => (elem.id === id))
    let appoListCopy = Object.assign({}, appoFormList[appoIndex])
    appoListCopy.notes = value
    const newAppoList = appoFormList.slice()
    newAppoList[appoIndex] = appoListCopy
    setAppoFormList(newAppoList)
}

const resetAppoFormList = () => {
    setAppoFormList([{id: 1, timestamp: "", date: "", time:"", type: 1, notes: ""}])
    setIdCount(2)
    setActiveIndex(0)
    setNumAddAppoForm("1")
}

const addAppointment = async () => {
    let timestampAppointmentsList = addTimeStamp(appoFormList)
    await getServerMgr().addAppointments({markerId: modalInfo.markerId, appointmentsList: timestampAppointmentsList}, () =>{
        updateData(() => {
            handleClose()            
            resetAppoFormList()
        })
    })
    updateData()
}

const addTimeStamp = (appoList) => {
    let returnList = []
    appoList.forEach((item) => {
        let shallowCopyItem = {...item}
        if(item.date) {
            let calculatedTimestamp = new Date(item.date + " " + (item.time ? item.time : "23:59:59")).valueOf() / 1000;
            shallowCopyItem["timestamp"] = calculatedTimestamp;
        } else {                
            shallowCopyItem["timestamp"] = null;
        }
        returnList.push(shallowCopyItem)
    });
    return returnList
}
//________________________

    const onChangeCarousel = (i) => {
        setActiveIndex(i)
    }

    const setPrevIndex = () => {
        if(activeIndex === 0) {
            setActiveIndex(appoFormList.length - 1)
        } else {
            setActiveIndex((prevState) => (prevState - 1))
        }
    }

    const setNextIndex = () => {
        if(activeIndex === appoFormList.length - 1) {
            setActiveIndex(0)
        } else {
            setActiveIndex((prevState) => (prevState + 1))
        }
    }

    const deleteAppoForm = () => {
        if(appoFormList.length !== 1) {
            let deleteIndex = activeIndex
            if(activeIndex === appoFormList.length - 1) {
                setActiveIndex(appoFormList.length - 2)
            }
            setAppoFormList((prevState) => (prevState.toSpliced(deleteIndex, 1)))
        }
    }

    const onClose = () => {   
        resetAppoFormList()  
        handleClose()
    }

    // const onConfirm = async (id) => {
    //     let timestampAppointmentsList = getUtils().addTimeStamp(getUtils().parseDate(appoDate))
    //     await getServerMgr().addAppointments({markerId: props.info?.id}, updateData)
    //     // setAddAppointmentFormList([])
    //     updateData()
    // }

    // const onChangeDate = (e) => {
    //     // let dateParsed = e.$y.toString() + "-" + (e.$M > 10 ? e.$M : "0"+e.$M) + "-" + (e.$D > 10 ? e.$D : "0"+e.$D)
    //     // setAppoDate(e.$d)
    //     setAppoDate(e.target.value)
    // }

    // const onChangeTime = (e) => {
    //     setAppoTime(e.target.value)
    // }

    // const parseAppoTypeList = (list) => {
    //     let parsedList = []
    //     list.forEach((item) => (
    //         parsedList.push({value: item.id, label: item.name})
    //     ))
    //     return parsedList
    // }

    // const onChangeType = (e) => {
    //     setAppoType(Number(e.target.value))
    // }

    // const onChangeNote = (e) => {
    //     setAppoNote(e.target.value)
    // }

    // const newAppoForm = () => {
    //     setAppoFormList((prevState) => ([...prevState, <NewAppointmentForm/>]))
    // }

    return (
        <>
        <Modal 
            show={show} 
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {"Nuovo intervento"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomCarousel
                    activeIndex={activeIndex}
                    onSelect={onChangeCarousel}
                    itemList={appoFormList.map((item) => (
                        <>
                            <NewAppointmentForm key={item.id} appoInfo={item} changeDate={changeDate} changeTime={changeTime} changeType={changeType} changeNotes={changeNotes} handleDropdownChange={changeType}></NewAppointmentForm>
                        </>
                    ))}
                />
            </Modal.Body>
            <Modal.Footer style={{justifyContent: "space-between"}}>
                <Button variant="secondary" onClick={setPrevIndex}>
                    <NavigateBeforeIcon/>
                </Button>
                <div style={{display: "flex"}}>
                    <TextField style={{width: "56px", textAlign: "left"}} value={numAddAppoForm} onChange={(e) => setNumAddAppoForm(e.target.value)}></TextField>
                    <Button variant="primary" onClick={newAppoForm} style={{marginRight: "10px"}}>
                        <AddIcon/>
                    </Button>
                    <Button variant="danger" onClick={deleteAppoForm}>
                        <DeleteForeverOutlinedIcon/>
                    </Button>
                </div>
                <Button variant="secondary" onClick={setNextIndex}>
                    <NavigateNextIcon/>
                </Button>
            </Modal.Footer>
            <Modal.Footer>
                <Button variant="secondary" onClick={addAppointment}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Cancella"}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}



const NewAppointmentForm = ({appoInfo, changeDate, changeTime, changeType, changeNotes/*, handleDropdownChange={changeType}*/}) => {

    //STORE    
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)    

    //STATE
    // const [appoType, setAppoType] = useState(1)
    // const [appoDate, setAppoDate] = useState(null)
    // const [appoTime, setAppoTime] = useState(null)
    // const [appoNote, setAppoNote] = useState("")

    //FUNCTION
    // const onChangeDate = (e) => {
    //     // let dateParsed = e.$y.toString() + "-" + (e.$M > 10 ? e.$M : "0"+e.$M) + "-" + (e.$D > 10 ? e.$D : "0"+e.$D)
    //     // setAppoDate(e.$d)
    //     setAppoDate(e.target.value)
    // }

    // const onChangeTime = (e) => {
    //     setAppoTime(e.target.value)
    // }

    const parseAppoTypeList = (list) => {
        let parsedList = []
        list.forEach((item) => (
            parsedList.push({value: item.id, label: item.name})
        ))
        return parsedList
    }

    // const onChangeType = (e) => {
    //     setAppoType(Number(e.target.value))
    // }

    // const onChangeNote = (e) => {
    //     setAppoNote(e.target.value)
    // }

    return(
        // <div style={{marginLeft: "10px", marginBottom: "10px"}}>
        //     {/* <div>--------------------</div> */}
        //     <hr></hr>
        //     <div className="label_style">DATA:</div>
        //     <input className="input_style" type={"date"} value={props.appoInfo.date} onChange={(e) => props.changeDate(props.appoInfo.id, e.target.value)} ref={dateInputRef}></input>
        //     <div className="label_style">ORARIO:</div>
        //     <input className="input_style" type={"time"} value={props.appoInfo.time} onChange={(e) => props.changeTime(props.appoInfo.id, e.target.value)} ref={timeInputRef}></input>
        //     <div className="label_style">TIPO:</div>
        //     {/* <input value={props.appoInfo.type} onChange={(e) => props.changeType(props.appoInfo.id, e.target.value)}></input>    */}

        //     <select className="select_style" onChange={(e) => props.handleDropdownChange(props.appoInfo.id, appointmentTypes[e.target.selectedIndex].id)}>
        //         {/* <option value="">Select an option</option> */}
        //         {appointmentTypes.map((option) => (
        //         <option key={option.id} value={option.name}>
        //             {option.name}
        //         </option>
        //         ))}
        //     </select>

        //     <div className="label_style">NOTE</div>
        //     <textarea className="input_style" value={props.appoInfo.notes} onChange={(e) => props.changeNotes(props.appoInfo.id, e.target.value)}></textarea>         
        // </div>
        <div>
            <h6 style={{marginBottom: "20px"}}>{"Aggiungere intervento:"}</h6>
            <div className='modalAddAppo_bodyContainer'>
                <div style={{width: "80%"}}>
                        {/* <DatePicker label={"Data esecuzione"} value={appoDate} onChange={onChangeDate} />
                        <TimePicker label={"Data esecuzione"} value={appoTime} onChange={onChangeTime} /> */}
                    <div className="modalAddAppo_infoContainer" > 
                        <div className="modalAddAppo_selectContainer"> 
                            <BasicSelect label={"Tipo intervento"} value={appoInfo.type} onChange={(e) => changeType(appoInfo.id, Number(e.target.value))} valuesIdList={parseAppoTypeList(appointmentTypes)} containerClassName={"sorter_container"} minWidth={190}/>                                
                        </div>                   
                    <div className='modalAddAppo_dateTimeContainer'>
                            <TextField label={"Data"} type='date' InputLabelProps={{ shrink: true }} value={appoInfo.date} onChange={(e) => changeDate(appoInfo.id, e.target.value)}/>
                            <TextField label={"Orario"} type='time' InputLabelProps={{ shrink: true }} value={appoInfo.time} onChange={(e) => changeTime(appoInfo.id, e.target.value)}/>
                        </div>
                        {/* <div><>Data esecuzione: &nbsp;</></div> */}
                        {/* <TextField label={"Data esecuzione"} type='date' shrink value={appoDate} onChange={onChangeDate}/>
                        <DatePicker label={"Data esecuzione"} value={appoDate} onChange={onChangeDate} />
                        <TimePicker label={"Data esecuzione"} value={appoTime} onChange={onChangeTime} /> */}
                        {/* <input type="date" value={completeDate} onChange={(e) => setCompleteDate(e.target.value)}></input> */}
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>                        
                        <TextField
                            style={{width: "100%", marginLeft: "8px"}}
                            id="outlined-multiline-static"
                            label="Note"
                            multiline
                            value={appoInfo.notes}
                            onChange={(e) => changeNotes(appoInfo.id, e.target.value)}
                        />
                    </div>                       
                    <div style={{height: "60px"}}></div>
                </div>
            </div>
        </div>    
    )
}