import { useEffect, useState } from "react"
import { getServerMgr } from "../managers/ServerMgr"
import "./createMarker.css"
import GenericButton from "../common/GenericButton"
import { getUtils } from "../managers/Utils"
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material"
import AddLocationIcon from '@mui/icons-material/AddLocation';

export default function CreateMarker(props) {

    const [markerName, setMarkerName] = useState("")
    const [markerNotes, setMarkerNotes] = useState("")
    const [adminSurname, setAdminSurname] = useState("")
    const [adminName, setAdminName] = useState("")
    const [adminTel, setAdminTel] = useState("")
    const [adminEmail, setAdminEmail] = useState("")
    const [adminNotes, setAdminNotes] = useState("")
    const [needWarning, setNeedWarning] = useState(false)

    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setMarkerName(props.newMarkerAddress)
    }, [props.newMarkerAddress])

    useEffect(() => {
        setMarkerName("")
        setMarkerNotes("")
        setAdminSurname("")
        setAdminName("")
        setAdminTel("")
        setAdminEmail("")
        setAdminNotes("")
        setNeedWarning(false)
    }, [props.isClosing])

    useEffect(() => {
        setMarkerName(props.newMarkerAddress ? props.newMarkerAddress : "")
        setMarkerNotes("")
        setAdminSurname("")
        setAdminName("")
        setAdminTel("")
        setAdminEmail("")
        setAdminNotes("")
        setNeedWarning(false)
    }, [props.showSlider, props.showSliderMobile])    
    
    const createMarker = () => {
        if(markerName && adminSurname) {
            getServerMgr().addMarker({markerLat: props.newMarkerPosition.lat, markerLng: props.newMarkerPosition.lng, markerName, markerNotes, adminSurname, adminName, adminTel, adminEmail, adminNotes, needWarning}, 
                (id) => {
                    props.closeModal()
                    props.onMarkerCreation(id)
                })
        } else {
            setShowError(true)
        }
    }

    return(
        // <div style={{width: "calc(100% - 30px)", height: "100%", padding: " 15px 15px 0 15px"}}>
        <div style={{width: "100%", height: "100%"}}>
            <h5>Condominio</h5>
                <div style={{marginBottom: "16px"}}>
                    <TextField label="Indirizzo" onChange={(e) => setMarkerName(e.target.value)} value={markerName} sx={{width: "100%"}}/>
                </div>
                
                <div style={{marginBottom: "16px"}}>
                    <TextField label="Note condominio" multiline onChange={(e) => setMarkerNotes(e.target.value)} value={markerNotes} sx={{width: "100%"}}/>
                </div>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormGroup aria-label="position">
                            <FormControlLabel 
                            sx={{display: "flex", width: "100%", height: "24px"}}
                            value={"Necessita avviso"}
                            control={<Checkbox onChange={(e) => setNeedWarning((prevState) => (!prevState))} checked={needWarning} />}
                            label={"Necessita avviso"}
                            labelPlacement="end"
                            />
                    </FormGroup>
                </FormControl>     

            <h5>Amministratore</h5>                         

                <div style={{marginBottom: "16px"}}>
                    <TextField label="Cognome" onChange={(e) => setAdminSurname(e.target.value)} value={adminSurname} sx={{width: "100%"}}/>
                </div>
                
                <div style={{marginBottom: "16px"}}>
                    <TextField label="Nome" onChange={(e) => setAdminName(e.target.value)} value={adminName} sx={{width: "100%"}}/>
                </div>

                <div style={{marginBottom: "16px"}}>
                    <TextField label="Telefono" onChange={(e) => {if(getUtils().checkIfNumber(e.target.value)) setAdminTel(e.target.value)}} value={adminTel} sx={{width: "100%"}}/>
                </div>
                
                <div style={{marginBottom: "16px"}}>
                    <TextField label="Email" onChange={(e) => setAdminEmail(e.target.value)} value={adminEmail} sx={{width: "100%"}}/>
                </div>
                <div style={{display: showError ? "block" : "none", color: "red", marginTop: "10px"}}>ERRORE: inserire nome del punto e cognome dell'admin</div>
        

                <div style={{height: "48px"}}></div>
                <div style={{position: "sticky", bottom: "20px", width: "100%", display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" startIcon={<AddLocationIcon />} onClick={createMarker}>
                        Crea marker
                    </Button>
                </div>
                

            </div>
    )
}

const AdministratorInfo = (props) => {
    return(
        <div style={{marginLeft: "10px"}}>
            <div>{"COGNOME: "+props.adminInfo?.surname}</div>
            <div>{"NOME: "+props.adminInfo?.name}</div>
            <div>{"TELEFONO: "+props.adminInfo?.telephone}</div>
            <div>{"EMAIL: "+props.adminInfo?.email}</div>
            <div>{"NOTE: "+props.adminInfo?.adm_notes}</div>
        </div>
    )
}

const AppointmentInfo = (props) => {
    return(
        <div style={{marginLeft: "10px", marginBottom: "10px"}}>
            <div>{"DATA: "+props.appointment?.date}</div>  
            <div>{"TIPO: "+props.appointment?.type_name}</div>  
            <div>{"COMPLETATO: "+props.appointment?.completed}</div>  
            <div>{"DATA COMPLETAMENTO: "+props.appointment?.completion_date}</div>  
            <div>{"NOTE: "+props.appointment?.appointment_notes}</div>            
        </div>
    )
}