import { useState } from "react"
import MarkerRow from "./MarkerRow";
import { MARKER_STATE } from "../enum/markerCompleted";
import MarkerList from "./MarkerList";
import AppointmentsList from "./AppointmentsList";
import { getServerMgr } from "../managers/ServerMgr";
import EditMarkerModal from "./EditMarkerModal";
import DeleteMarkerModal from "./DeleteMarkerModal";
import { useMapitoStore } from "../zustand/MapitoStore";
import { ICONS } from "../assets/icons";
import SideSlider from "../sideSlider/SideSlider";
import Filters from "../filters/Filters";
import AppoFilters from "../filters/AppoFilters";
import MarkerTable from "../ui/markerList/MarkerTable";
import { BottomNavigation, BottomNavigationAction, Typography } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import AppoTable from "../ui/markerList/AppoTable";

import "./TabContainer.css"
import SideSheet from "../ui/sideSheet/SideSheet";
import MarkerSorter from "../filters/MarkerSorter";
import AppoSorter from "../filters/AppoSorter";

export default function TabContainer(props) {
        
    //STORE
    const markersList = useMapitoStore((state) => state.markersList)
    const appointmentsList = useMapitoStore((state) => state.appointmentsList)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)
    const updateData = useMapitoStore((state) => state.updateData)
    const mapCenter = useMapitoStore((state) => state.mapCenter)
    const setMapCenter = useMapitoStore((state) => state.setMapCenter)
    const mapZoom = useMapitoStore((state) => state.mapZoom)
    const setMapZoom = useMapitoStore((state) => state.setMapZoom)

    const [tabSelected, setTabSelected] = useState("markers")
    const [showEditModal, setShowEditModal] = useState(false)
    const [editMarkerInfo, setEditMarkerInfo] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteMarkerInfo, setDeleteMarkerInfo] = useState({})

    const [showFilterSlider, setShowFilterSlider] = useState(false)
    const [showFilterSliderMobile, setShowFilterSliderMobile] = useState(false)

    const getTabColor = () => {
        switch(tabSelected) {
            case "markers":
                return "#402d4d";
            case "appo":
                return "#483654";
            case "admin":
                return "#574763";
            default:
                return "#000000";
        }
    }

    const onClickEdit = (item) => {
        setEditMarkerInfo(item)
        setShowEditModal(true)
    }

    const onMarkerEdit = () => {
        setShowEditModal(false)
    } 

    const onClickDelete = (item) => {
        setDeleteMarkerInfo(item)
        setShowDeleteModal(true)
    }

    const deleteMarker = (item) => {
        getServerMgr().deleteMarker({markerId: item.id}, updateData)
        setShowDeleteModal(false);
    }

    return(
        <div style={{display: props.show ? "block" : "none"}}>
            <div className="tabSelected_title">
                <div>{tabSelected === "markers" ? "Marker" : "Interventi"}</div>
                <div className="flexContainer">
                    <SideSheet 
                        title={"Ordinamento"}
                        customButton={
                        <div className="flexContainer tabContainer_titleButton_container">
                            <div className={"tabContainer_titleButton_button"} style={{backgroundImage: ICONS.sorting_black, width: "40px"}}></div>
                            {/* <div className="tabContainer_titleButton_label">Ordina</div> */}
                        </div>} 
                    >
                        {
                            tabSelected === "markers" 
                            ? 
                            <MarkerSorter/>
                            : 
                            <AppoSorter/>
                        }
                    </SideSheet>
                    <SideSheet 
                        title={"Filtri"}
                        customButton={
                        <div className="flexContainer tabContainer_titleButton_container">
                            <div className={"tabContainer_titleButton_button"} style={{backgroundImage: ICONS.filters_black, width: "40px"}}></div>
                            {/* <div className="tabContainer_titleButton_label">Filtri</div> */}
                        </div>}
                    >
                        {
                            tabSelected === "markers" 
                            ? 
                            <Filters/>
                            : 
                            <AppoFilters/>
                        }
                    </SideSheet>
                </div>
            </div>
            <div className="flexContainer">
                <div className="tabContainer_left_column">
                    {                        
                        tabSelected === "markers" 
                        ? 
                        <>
                            <Typography variant="h6" gutterBottom component="div">
                                Ordinamento
                            </Typography>
                            <MarkerSorter/>
                            <Typography variant="h6" gutterBottom component="div">
                                Filtri e ricerca
                            </Typography>
                            <Filters/>
                        </>
                        : 
                        <>
                            <Typography variant="h6" gutterBottom component="div">
                                Ordinamento
                            </Typography>
                            <AppoSorter/>
                            <Typography variant="h6" gutterBottom component="div">
                                Filtri e ricerca
                            </Typography>
                            <AppoFilters/>
                        </>
                    }
                </div>
                <div className="tabContainer_right_column">
                    <MarkerTable show={tabSelected === "markers"} showMarkerOnMap={props.showMarkerOnMap} />
                    <AppoTable show={tabSelected === "appo"}/>
                </div>
            </div>
            <BottomNavigation
                showLabels
                value={tabSelected}
                onChange={(event, newValue) => {
                    setTabSelected(newValue);
                }}
                className={"tabContainer_bottomNav"}
                >
                <BottomNavigationAction label="Markers" value={"markers"} icon={<LocationOnIcon />} />
                <BottomNavigationAction label="Interventi" value={"appo"} icon={<EventIcon />} />
            </BottomNavigation>
        </div>
    )
}