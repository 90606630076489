import { useState } from "react"
import SearchMapBar from "./SearchMapbar"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

export default function SearchPage({className, setSelected, setMapCenter, onCreateMarker}) {

    const [showSearchPage, setShowSearchPage] = useState(false)

    const [searchValue, setSearchValue] = useState("")

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
      } = usePlacesAutocomplete({
        requestOptions:{language: "it"}
      });
    
      const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
    
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);

        setShowSearchPage(false)

        setSelected({ lat, lng });
        onCreateMarker({ lat, lng }, address)
        setMapCenter({ lat, lng });
      };

    return(
        <div className={className} style={{position: "absolute", top: 0, left: 0}}>
            <div style={{position: "relative"}}>
                <SearchMapBar onFocus={() => setShowSearchPage(true)} showCancelButton={showSearchPage} onCancel={() => setShowSearchPage(false)} searchValue={value} setSearchValue={setValue}/>
                <div style={{/*display: showSearchPage ? "block" : "none"*/ visibility: showSearchPage ? "visible" : "hidden", opacity: showSearchPage ? "1" : "0", transition: "visibility 0s, opacity 0.2s linear", backgroundColor: "#e0e0e0", width: "100vw", height: "calc(100vh - 56px)", zIndex: 5, position: "relative"}}>
                    <div style={{height: "100px"}}></div>
                    <hr style={{borderColor: "black"}}/>
                    <div style={{padding: "10px 20px"}}>
                        {data.map(({ place_id, description }) => (
                            <div key={place_id} onClick={() => {handleSelect(description)}} className='autocompleteResult'>
                            {description}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}