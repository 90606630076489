import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMapitoStore } from '../../zustand/MapitoStore';
import AppoRow from './AppoRow';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import "./AppoTable.css"

// function createData(row) {
//   return {row.address, row.appointments, row.marker_notes, row.needWarning, row.administrator}
// }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#123456",//theme.palette.grey[500],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const calculateNotCompletedAppointments = (appointmentsList) => {
    let notCompletedCount = 0;
    appointmentsList.forEach((item) => {
        if(!item.completed) {notCompletedCount++}
    });
    return notCompletedCount;
} 

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
               {row.appointments.map((item) => (
                <AppoRow showMarker key={item.appointment_id} appo={item}></AppoRow>
               ))}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function AppoTable({show}) {

  const appointmentsList = useMapitoStore((store) => store.appointmentsList)
  const appoFilters = useMapitoStore((state) => state.appoFilters)
  const filterAppo = useMapitoStore((state) => state.filterAppo)
  const sortAppo = useMapitoStore((state) => state.sortAppo)
  const appoSortBy = useMapitoStore((state) => state.appoSortBy)
  const appoSortingType = useMapitoStore((state) => state.appoSortingType)
  

  return (
    // <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 56px - 56px - 60px - 80px)" }} style={{display: show ? "block" : "none", padding: "15px"}}>
    //   <Table stickyHeader aria-label="sticky table">
    //     <TableHead>
    //       <TableRow>
    //         <StyledTableCell style={{minWidth: "70"}}></StyledTableCell>
    //         <StyledTableCell style={{minWidth: "270"}}>
    //           <div>
    //             <div>{"Tipo"}</div>
    //             <div>{"(data)"}</div>
    //           </div>
    //         </StyledTableCell>
    //         <StyledTableCell style={{minWidth: "270"}}>Indirizzo</StyledTableCell>
    //         <StyledTableCell align="right" className={"hide_mobile"} style={{minWidth: "150"}}>Admin</StyledTableCell>
    //       </TableRow>
    //     </TableHead>
    //   </Table>
    //   <TableBody>
    //     {appointmentsList.map((appo) => {
    //       return <TableRow><AppoRow showMarker key={appo.id} appo={appo}/></TableRow>
    //     })}
    //   </TableBody>
    // </TableContainer>
    <div className="appoTable_container" style={{display: show ? "block" : "none"}}>
      <div style={{backgroundColor: "#123456", height: "57px", padding: "0 16px"}}>
      <div className="appoRow_container">
              <div className="flexContainer">
                  <div style={{width: "40px", marginRight: "8px"}}></div>
                  <div className='appoTable_textLabel_doubleLine appoTable_textLabel_doubleLine_header'>
                      <div className='appoTable_textLabel' style={{textAlign: "left"}}>{"Tipo Intervento"}</div>
                      <div className='appoTable_textLabel'>{"(Data programmata)"}</div>
                  </div>
              </div>
              <div className="appoRow_completionDate" style={{color: "white"}}>
                  <div className='appoTable_textLabel'>{"Data Compl."}</div>
              </div>
              <div className="appoRow_button_container hide_mobile" style={{color: "white"}}>
                  <div className='appoTable_textLabel'>{""}</div>
              </div>
          </div>
      </div>
      <div className='appoTable_appoRow_container'>
        {appointmentsList.sort(sortAppo).map((appo) => {
          if(filterAppo(appo, appoFilters)) {
            return (
              <AppoRow showMarker key={appo.appointment_id} appo={appo} toRefreshSortBy={appoSortBy} toRefreshSortType={appoSortingType}/>
            )
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}