import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ModalContainer from './modal/ModalContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalComponent from './ui/modal/ModalComponent';
import ModalCompleteAppo from './ui/modal/ModalCompleteAppo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';
import ModalAddAppo from './ui/modal/ModalAddAppo';
import ModalDeleteAppo from './ui/modal/ModalDeleteAppo';
import ModalDeleteMarker from './ui/modal/ModalDeleteMarker';
import ModalEditAppo from './ui/modal/ModalEditAppo';
import ModalEditMarker from './ui/modal/ModalEditMarker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='it'>
      <App />
      <ModalCompleteAppo/>
      <ModalAddAppo/>
      <ModalDeleteAppo/>
      <ModalDeleteMarker/>
      <ModalCompleteAppo/>
      <ModalEditAppo/>
      <ModalEditMarker/>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
