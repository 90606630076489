import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import YellowMarker from './marker/YellowMarker';
import { useAutocomplete } from '@vis.gl/react-google-maps';
import "./App.css"
import { useEffect, useRef, useState } from "react"

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import BlueMarker from './marker/BlueMarker'; 
import GreenMarker from './marker/GreenMarker';
import TopToolbar from './toolbar/TopToolbar';
import GoogleMapContainer from './map/GoogleMapComponent';
import MarkerList from './markerList/MarkerList';
import { getServerMgr } from './managers/ServerMgr';
import { getUtils } from './managers/Utils';
import TabContainer from './markerList/TabContainer';
import { useMapitoStore } from './zustand/MapitoStore';
import NavbarComponent from './ui/navbar/NavbarComponent';
import SettingsPage from './settingsPage/SettingsPage';


function initSingleton() {
  return new Promise((resolve, reject) => {
    getServerMgr( () =>
      getUtils(resolve)
    )
  })
}
export default function App () {
  const updateData = useMapitoStore((state) => state.updateData)
  const setMapCenter = useMapitoStore((state) => state.setMapCenter)
  const setMapZoom = useMapitoStore((state) => state.setMapZoom)
  const mapZoom = useMapitoStore((state) => state.mapZoom)

  const Bari = {lat: 41.11148, lng: 16.8554}

  const [singletonLoaded, setSingletonLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState("map")
  const [openFilters, setOpenFilters] = useState(false)

  useEffect(() => {
    initSingleton()
    .then(async () => {
      await updateData()
      setSingletonLoaded(true)
    })
  }, [])

  useEffect(() => {
    if(mapZoom === -1) {
      setMapZoom(17)
    }
  }, [mapZoom])

  const showMarkerOnMap = (coord) => {
    setCurrentPage("map")
    setMapCenter(coord)
    setMapZoom(-1) //TO RERENDER THE MAP ZOOM WHEN A MARKER IS CLICKED IN THE MARKER LIST, WITHOUT USING onZoomChanged THAT MAKES EVERYTHING SLOW
  } 

  function getOperatingSystem(window) {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
  
    return operatingSystem;
  }
  
  function getBrowser(window) {
    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    else { console.log('Others'); }
  
    return currentBrowser;
  }

  if(singletonLoaded){
    return(
        <div>
          <NavbarComponent setCurrentPage={setCurrentPage} currentPage={currentPage} openFilters={setOpenFilters}/>
          <div style={{height: "56px"}}></div>
          <div>
            <GoogleMapContainer show={currentPage === "map"} openFilters={openFilters} handleShowToParent={setOpenFilters}></GoogleMapContainer>
            <TabContainer show={currentPage === "list"} showMarkerOnMap={showMarkerOnMap} openFilters={openFilters}></TabContainer>     
            <SettingsPage show={currentPage === "settings"} /> 
          </div>
        </div>
    )
  } else {
    return (
      <div>
        LOADING
      </div>
    )
  }
};