//import { ServerFetch } from "../enum/ServerFetch";

import axios from "axios";


let serverMgr = null;

export function getServerMgr(cb) {
    if(serverMgr === null) {
        initServerMgr(() => {
            serverMgr.init(cb);
            //return serverMgr 
        })
    } else {        
        return serverMgr;
    }
}

function initServerMgr(cb) {
    serverMgr = {};

    serverMgr.init = (cb) => {
        if (cb) cb();
    }

    serverMgr.requestFetchData = async (service, args) => {

        let data = args
                    ?
                    JSON.stringify({
                        "service": service,
                        ...args 
                    })
                    :
                    JSON.stringify({
                        "service": service
                    })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'https://mapito.altervista.org/phpScripts/executeQuery.php', //PROD
            url: 'https://lillakes.altervista.org/mapito/phpScripts/executeQuery.php', //TEST
            // url: "http://localhost:80/executeQuery.php", //DEV
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        const response = await axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
          console.log("ERROR requestFetchData: " + error);
        });

        return response;
        // try{
        //     //const response = await fetch("http://localhost:80/executeQuery.php", { //DEV
        //     const response = await fetch("https://mapito.altervista.org/scripts/executeQuery.php", { //PROD
        //         method: "POST",
        //         mode: "cors",
        //         headers: {
        //             "Content-Type": "application/json; charset=utf-8",
        //             // "Access-Control-Allow-Origin": "*",
        //             // "Access-Control-Allow-Credentials": "true",
        //             // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        //             // "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        //         },
        //         body: args
        //             ?
        //             JSON.stringify({
        //                 "service": service,
        //                 ...args 
        //             })
        //             :
        //             JSON.stringify({
        //                 "service": service
        //             })
        //     })
        //     const data = await response.text();
        //     console.log("requestFetchData: " + data)
        //     return JSON.parse(data); 
        // }
        // catch (error) {
        //     console.log("ERROR requestFetchData: " + error)
        // }
    }

    serverMgr.getFullData = async (cb) => {
        let result = await serverMgr.requestFetchData("getAll")
        if(cb) {
            console.log("getFullData: " + result)
            cb(result)
        }
        else {
            console.log("getFullData: " + result)
            return result
        }
    }

    serverMgr.getAppointmentTypes = async (cb) => {
        let result = await serverMgr.requestFetchData("getAppointmentTypes")
        if(cb) {
            console.log("getAppointmentTypes: " + result)
            cb(result)
        }
        else {
            console.log("getAppointmentTypes: " + result)
            return result
        }
    }

    serverMgr.addMarker = async (args, cb) => {
        let result = await serverMgr.requestFetchData("addMarker", args)
        if(cb) {
            console.log("addMarker: " + result)
            cb(result)
        }
        else {
            console.log("addMarker: " + result)
            return result
        }
    }

    serverMgr.addAppointments = async (args, cb) => {
        let result = await serverMgr.requestFetchData("addAppointments", args)
        if(cb) {
            console.log("addAppointments: " + result)
            cb(/*result*/)
        }
        else {
            console.log("addAppointments: " + result)
            return null //result
        }
    }

    serverMgr.deleteMarker = async (args, cb) => {
        let result = await serverMgr.requestFetchData("deleteMarker", args)
        if(cb) {
            console.log("deleteMarker: " + result)
            cb(/*result*/)
        }
        else {
            console.log("deleteMarker: " + result)
            return null //result
        }
    }

    serverMgr.deleteAppo = async (args, cb) => {
        let result = await serverMgr.requestFetchData("deleteAppo", args)
        if(cb) {
            console.log("deleteAppo: " + result)
            cb(/*result*/)
        }
        else {
            console.log("deleteAppo: " + result)
            return null //result
        }
    }

    serverMgr.deleteAppo = async (args, cb) => {
        let result = await serverMgr.requestFetchData("deleteAppo", args)
        if(cb) {
            console.log("deleteAppo: " + result)
            cb(/*result*/)
        }
        else {
            console.log("deleteAppo: " + result)
            return null //result
        }
    }

    serverMgr.setCompletedAppo = async (args, cb) => {
        let result = await serverMgr.requestFetchData("setCompletedAppo", args)
        if(cb) {
            console.log("setCompletedAppo: " + result)
            cb(/*result*/)
        }
        else {
            console.log("setCompletedAppo: " + result)
            return null //result
        }
    }

    serverMgr.editAppo = async (args, cb) => {
        let result = await serverMgr.requestFetchData("editAppo", args)
        if(cb) {
            console.log("editAppo: " + result)
            cb(/*result*/)
        }
        else {
            console.log("editAppo: " + result)
            return null //result
        }
    }

    serverMgr.editMarker = async (args, cb) => {
        let result = await serverMgr.requestFetchData("editMarker", args)
        if(cb) {
            console.log("editMarker: " + result)
            cb(/*result*/)
        }
        else {
            console.log("editMarker: " + result)
            return null //result
        }
    }

    serverMgr.getCompleted = async (args, cb) => {
        let result = await serverMgr.requestFetchData("getCompleted", args)
        if(cb) {
            console.log("getCompleted: " + result)
            cb(result)
        }
        else {
            console.log("getCompleted: " + result)
            return result
        }
    }

    if (cb) cb();
}