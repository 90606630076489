//import { ServerFetch } from "../enum/ServerFetch";

let utils = null;

export function getUtils(cb) {
    if(utils === null) {
        initUtils(() => {
            utils.init(cb);
            //return utils 
        })
    } else {        
        return utils;
    }
}

function initUtils(cb) {
    utils = {};

    utils.init = (cb) => {
        if (cb) cb();
    }

    utils.parseDate = (date) => {
        let tempDate = new Date(Date.parse(date));
        let day = tempDate.getDate()
        let resultDate = day >= 10 ? day : ("0" + day) 
        resultDate += "/"
        let month = tempDate.getMonth() + 1
        resultDate += month >= 10 ? month : ("0" + month) 
        resultDate += "/" + tempDate.getFullYear()
        return resultDate
    }

    utils.dateToString = (completeDate) => {
        let dateParsed = completeDate.getFullYear() + "-" + ((completeDate.getMonth()+1) > 10 ? (completeDate.getMonth()+1) : ("0"+(completeDate.getMonth()+1))) + "-" + (completeDate.getDate() > 10 ? completeDate.getDate() : ("0"+completeDate.getDate()))
        return dateParsed; 
    }

    utils.getTimestamp = (date, time) => {
        let timestamp = null
        if(date) {
            let calculatedTimestamp = new Date(date + " " + (time ? time : "23:59:59")).valueOf() / 1000;
            timestamp = calculatedTimestamp;
        }
        return timestamp;
    }

    utils.checkIfNumber = (value) => {
        const re = /^[0-9\b]+$/;
        return (value === '' || re.test(value))
    }

    utils.addTimeStamp = (date, time) => {
        if(date) {
            let calculatedTimestamp = new Date(date + " " + (time ? time : "23:59:59")).valueOf() / 1000;
            return calculatedTimestamp;
        } else {                
            return null;
        }
    }

    if (cb) cb();
}