import BasicSelect from "../ui/input/select/BasicSelect";
import { useMapitoStore } from "../zustand/MapitoStore";

export default function MarkerSorter({}) {

    const markerSortBy = useMapitoStore((state) => state.markerSortBy)
    const setMarkerSorter = useMapitoStore((state) => state.setMarkerSorter)
    const markerSortingList = useMapitoStore((state) => state.markerSortingList)

    const markerSortingType = useMapitoStore((state) => state.markerSortingType)
    const setMarkerSortingType = useMapitoStore((state) => state.setMarkerSortingType)
    const sortingTypeList = useMapitoStore((state) => state.sortingTypeList)
    
    return(
        <>
            <BasicSelect label={"Criterio"} value={markerSortBy} onChange={setMarkerSorter} valuesIdList={markerSortingList} style={{marginBottom: "20px"}}/>
            <BasicSelect label={"Ordine"} value={markerSortingType} onChange={setMarkerSortingType} valuesIdList={sortingTypeList}/>
        </>
    )
}