import { useRef } from "react";
import GenericButton from "../common/GenericButton";
import { useMapitoStore } from "../zustand/MapitoStore";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material";
import { Label } from "@mui/icons-material";

import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

function Filters(){
    const appointmentsTypes = useMapitoStore((state) => state.appointmentTypes)
    const numAppo = useMapitoStore((state) => state.numAppo)
    const workingState = useMapitoStore((state) => state.workingState)
    const needWarningList = useMapitoStore((state) => state.needWarningList)
    const markerFilters = useMapitoStore((state) => state.markerFilters)
    const setMarkerFiltersAddress = useMapitoStore((state) => state.setMarkerFiltersAddress)
    const setMarkerFiltersAdministrator = useMapitoStore((state) => state.setMarkerFiltersAdministrator)
    const setMarkerFiltersNumAppo = useMapitoStore((state) => state.setMarkerFiltersNumAppo)
    const setMarkerFiltersNeedWarning = useMapitoStore((state) => state.setMarkerFiltersNeedWarning)
    const setMarkerFiltersType = useMapitoStore((state) => state.setMarkerFiltersType)
    const setMarkerFiltersWorkingState = useMapitoStore((state) => state.setMarkerFiltersWorkingState)
    const setMarkerFiltersDateFrom = useMapitoStore((state) => state.setMarkerFiltersDateFrom)
    const setMarkerFiltersDateTo = useMapitoStore((state) => state.setMarkerFiltersDateTo)
    const resetFilters = useMapitoStore((state) => state.resetFilters)
    
    const dateFromInputRef = useRef(null);
    const dateToInputRef = useRef(null);

    return(
        <>

                <div style={{marginBottom: "16px"}}>
                    <FormLabel component="legend">Indirizzo</FormLabel>
                    <TextField label="Indirizzo" onChange={(e) => setMarkerFiltersAddress(e.target.value)} value={markerFilters.address} sx={{width: "100%"}}/>
                </div>
                
                <div style={{marginBottom: "16px"}}>
                    <FormLabel component="legend">Amministratore</FormLabel>
                    <TextField label="Amministratore" onChange={(e) => setMarkerFiltersAdministrator(e.target.value)} value={markerFilters.administrator} sx={{width: "100%"}}/>
                </div>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormLabel component="legend">N. interventi</FormLabel>
                    <FormGroup aria-label="position">
                        {numAppo.map((item) => (
                            // <div key={item.id} style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            //     <label className="content_info" htmlFor="Cockroaches">{item.value}</label>
                            //     <input className="checkbox_style" onChange={() => {setMarkerFiltersNumAppo(item.id, markerFilters.numAppo.includes(item.id))}} checked={markerFilters.numAppo.includes(item.id)} id="Cockroaches" type="checkbox" value="Cockroaches"></input>
                                
                            // </div>
                            <FormControlLabel 
                            sx={{display: "flex", justifyContent: "space-between", width: "100%", height: "24px"}}
                            value={item.value}
                            control={<Checkbox onChange={() => {setMarkerFiltersNumAppo(item.id, markerFilters.numAppo.includes(item.id))}} checked={markerFilters.numAppo.includes(item.id)} />}
                            label={item.value}
                            labelPlacement="start"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormLabel component="legend">Richiede avviso</FormLabel>
                    <FormGroup aria-label="position">
                        {needWarningList.map((item) => (
                            <FormControlLabel 
                            sx={{display: "flex", justifyContent: "space-between", width: "100%", height: "24px"}}
                            value={item.value}
                            control={<Checkbox onChange={() => setMarkerFiltersNeedWarning(item.id, markerFilters.needWarning.includes(item.id))} checked={markerFilters.needWarning.includes(item.id)} />}
                            label={item.value}
                            labelPlacement="start"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormLabel component="legend">Tipo interventi</FormLabel>
                    <FormGroup aria-label="position">
                        {appointmentsTypes.map((item) => (
                            <FormControlLabel 
                            sx={{display: "flex", justifyContent: "space-between", width: "100%", height: "24px"}}
                            value={item.name}
                            control={<Checkbox onChange={() => {setMarkerFiltersType(Number(item.id), markerFilters.types.includes(Number(item.id)))}} checked={markerFilters.types.includes(Number(item.id))} />}
                            label={item.name}
                            labelPlacement="start"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormLabel component="legend">Stato del lavoro</FormLabel>
                    <FormGroup aria-label="position">
                        {workingState.map((item) => (
                            <FormControlLabel 
                            sx={{display: "flex", justifyContent: "space-between", width: "100%", height: "24px"}}
                            value={item.value}
                            control={<Checkbox onChange={() => {setMarkerFiltersWorkingState(item.id, markerFilters.workingState.includes(item.id))}} checked={markerFilters.workingState.includes(item.id)} />}
                            label={item.value}
                            labelPlacement="start"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

                <FormLabel component="legend" style={{marginBottom: "15px"}}>Date</FormLabel>
                    {/* <DatePicker label={"dal"} value={markerFilters.dateFrom ? markerFilters.dateFrom : null} max={markerFilters.dateTo} onChange={(e) => {setMarkerFiltersDateFrom(e.target.value)}} />
                    <DatePicker label={"al"} style={{height: "40px"}} value={markerFilters.dateTo ? markerFilters.dateTo : null} min={markerFilters.dateFrom} onChange={(e) => {setMarkerFiltersDateTo(e.target.value)}} /> */}
                <div style={{display: "flex", flexDirection: "column"}}>
                    <TextField label={"dal"} type='date' InputLabelProps={{ shrink: true }} placeholder='Data' InputProps={{inputProps: { max: markerFilters.dateTo} }} value={markerFilters.dateFrom} onChange={(e) => setMarkerFiltersDateFrom(e.target.value)} style={{marginBottom: "15px"}} sx={{width: "210px"}}/>
                    <TextField label={"al"} type='date' InputLabelProps={{ shrink: true }} placeholder='Orario' InputProps={{inputProps: { min: markerFilters.dateFrom} }} value={markerFilters.dateTo} onChange={(e) => setMarkerFiltersDateTo(e.target.value)} sx={{width: "210px"}}/>            
                </div>
                <div style={{height: "48px"}}></div>
                <div style={{position: "sticky", bottom: "20px", width: "100%", display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" startIcon={<RestartAltIcon />} onClick={resetFilters}>
                        Reset filtri
                    </Button>
                </div>

        </>
    );
}

export default Filters;