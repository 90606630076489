import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMapitoStore } from '../../zustand/MapitoStore';
import EditMarkerModal from '../../markerList/EditMarkerModal';
import DeleteMarkerModal from '../../markerList/DeleteMarkerModal';
import CompleteAppoModal from '../../modal/CompleteAppoModal';
import DeleteAppoModal from '../../modal/DeleteAppoModal';
import EditAppoModal from '../../modal/EditAppoModal';
import { getServerMgr } from '../../managers/ServerMgr';
import { DatePicker } from '@mui/x-date-pickers';
import { getUtils } from '../../managers/Utils';
import { TextField } from '@mui/material';
import "./ModalCompleteAppo.css"

export default function ModalCompleteAppo(props) {
    const show = useMapitoStore((state) => state.showCompleteModal)
    const setShow = useMapitoStore((state) => state.setShowCompleteModal)
    const modalInfo = useMapitoStore((state) => state.completeModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [completeDate, setCompleteDate] = useState(null)
    const [completeNotes, setCompleteNotes] = useState("")
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setCompleteNotes(modalInfo?.appointment_notes)
    }, [modalInfo])

    const onClose = () => {  
        setCompleteDate(null)  
        setCompleteNotes("")    
        handleClose()
    }

    const onConfirm = (id, toComplete) => {
        if(modalInfo?.completed) {
            getServerMgr().setCompletedAppo({appoId: id, toComplete: !modalInfo?.completed}, () => {
                updateData(
                    onClose
                )}
            )
        } else {
            // let dateParsed = getUtils().dateToString(completeDate); //completeDate.getFullYear() + "-" + ((completeDate.getMonth()+1) > 10 ? (completeDate.getMonth()+1) : ("0"+(completeDate.getMonth()+1))) + "-" + (completeDate.getDate() > 10 ? completeDate.getDate() : ("0"+completeDate.getDate()))
            if(completeDate) {
                getServerMgr().setCompletedAppo({appoId: id, completeDate: completeDate, completeNotes: completeNotes, toComplete: !modalInfo?.completed}, () => {
                    updateData(
                        onClose
                    )}
                )
            } else {
                setShowError(true)
            }
        }
    }

    const onChangeDate = (date) => {
        setCompleteDate(date)
        setShowError(false)
    }

    const onChangeNotes = (notes) => {
        setCompleteNotes(notes)
    }

    return (
        <>
        <Modal 
            show={show} 
            onHide={handleClose}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modalInfo?.completed ? "Annulla completamento" : "Completa intervento"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    modalInfo?.completed
                    ?
                    <div>
                        <h6>{"Annullare il completamento dell'intervento " + modalInfo?.type_name}</h6>
                    </div>
                    :
                    <div>
                        <h6 style={{marginBottom: "20px"}}>{"Completare l'intervento " + modalInfo?.type_name}</h6>
                        <div className="flexContainer_centering">
                            {/* <div><>Data esecuzione: &nbsp;</></div> */}
                            {/* <DatePicker label={"Data esecuzione"} value={completeDate} onChange={onChangeDate} /> */}
                            <TextField label={"Data esecuzione"} type='date' InputLabelProps={{ shrink: true }} placeholder='Data' value={completeDate} onChange={(e) => {onChangeDate(e.target.value)}} error={showError} helperText={showError ? "Inserire data" : ""}/>
                    
                
                            {/* <input type="date" value={completeDate} onChange={(e) => setCompleteDate(e.target.value)}></input> */}
                        </div>
                        <div className="flexContainer_centering modalCompleteAppo_textfield_container">
                            {/* <div><>Data esecuzione: &nbsp;</></div> */}
                            {/* <DatePicker label={"Data esecuzione"} value={completeDate} onChange={onChangeDate} /> */}
                            <TextField label={"Note"} type="text" value={completeNotes} onChange={(e) => {onChangeNotes(e.target.value)}} multiline className="modalCompleteAppo_textfield"/>
                    
                
                            {/* <input type="date" value={completeDate} onChange={(e) => setCompleteDate(e.target.value)}></input> */}
                        </div>
                    </div>
                }        
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onConfirm(modalInfo.appointment_id, modalInfo.completed)}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Cancella"}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}