import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

export default function BasicSelect({valuesIdList, onChange, value, label, minWidth = 210, containerClassName, style}) {

  return (
    <div>
      {/* <Box sx={{ m: 1, minWidth: minWidth }} size="small" >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={label}
            onChange={onChange}
          >
            {
              valuesIdList.map((item) => (
                <MenuItem value={item.value} name={item.label}>{item.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box> */}
      <TextField
          id="outlined-select-currency-native"
          select
          value={value}
          label={label}
          onChange={onChange}
          SelectProps={{
            native: true,
          }}
          className={containerClassName}
          sx={{
            minWidth: minWidth + "px"
          }}
          style={style}
        >
          {valuesIdList.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
    </div>
  );
}