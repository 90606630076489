import { cloneElement, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function SideSheet({title, children, noButton, showFromParent, handleShowToParent, placement="start", buttonLabel="", buttonClassname, buttonStyle, customButton = false}) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        if(handleShowToParent) {
            handleShowToParent(false)
        }
    }
    const handleShow = () => {
        setShow(true);
        if(handleShowToParent) {
            handleShowToParent(true)
        }
    }

    useEffect(() => {
        setShow(showFromParent)
    } , [showFromParent])

  return (
    <>
        {
            noButton
            ?
            null
            :
            (
              customButton
              ?
              cloneElement(customButton, {onClick: handleShow})
              :
              <Button variant="" className={buttonClassname} style={buttonStyle} onClick={handleShow}>
                {buttonLabel}
              </Button>
            )
        }

      <Offcanvas show={show} onHide={handleClose} placement={placement}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {cloneElement(children, {closeModal: handleClose})}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

