import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./NavbarComponent.css"
import { useMapitoStore } from '../../zustand/MapitoStore';
import { Button } from 'react-bootstrap';

import RefreshIcon from '@mui/icons-material/Refresh';

export default function NavbarComponent({setCurrentPage, openFilters, parentStyle, parentClassName}) {
  const statusBarHeight = useMapitoStore((state) => state.statusBarHeight)
  const setStatusBarHeight = useMapitoStore((state) => state.setStatusBarHeight)
  const updateData = useMapitoStore((state) => state.updateData)

  return (
    <Navbar fixed='top' collapseOnSelect expand="lg" className={"navbar_container "+parentClassName} style={{...parentStyle, boxShadow: "0px 3px 6px 0px #123456"}}> {/*bg-body-tertiary*/}
      <Container>
        <Navbar.Brand href="#home" className='navbar_text'>Mapito</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => setCurrentPage("map")} href="#home" className='navbar_text'>Mappa</Nav.Link>
            <Nav.Link onClick={() => setCurrentPage("list")} href="#link" className='navbar_text'>Lista</Nav.Link>
            <Nav.Link onClick={() => setCurrentPage("settings")} href="#settings" className='navbar_text'>Impostazioni</Nav.Link>

          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Button variant="primary" onClick={() => updateData()} style={{backgroundColor: "#123456"}}>
              <RefreshIcon/>{"Aggiorna"}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}