import Carousel from 'react-bootstrap/Carousel';

export default function CustomCarousel({itemList, activeIndex, onSelect}) {
  return (
    <Carousel controls={false} wrap={true} indicators={true} interval={9999999} variant='dark' activeIndex={activeIndex} onSelect={onSelect}>
        {
            itemList.map((item) => (
                <Carousel.Item>
                    {item}
                </Carousel.Item>
            ))
        }

    </Carousel>
  );
}