import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMapitoStore } from '../../zustand/MapitoStore';
import EditMarkerModal from '../../markerList/EditMarkerModal';
import DeleteMarkerModal from '../../markerList/DeleteMarkerModal';
import CompleteAppoModal from '../../modal/CompleteAppoModal';
import DeleteAppoModal from '../../modal/DeleteAppoModal';
import EditAppoModal from '../../modal/EditAppoModal';
import { getServerMgr } from '../../managers/ServerMgr';
import { DatePicker } from '@mui/x-date-pickers';
import { getUtils } from '../../managers/Utils';

export default function ModalDeleteMarker(props) {
    const show = useMapitoStore((state) => state.showDeleteMarkerModal)
    const setShow = useMapitoStore((state) => state.setShowDeleteMarkerModal)
    const modalInfo = useMapitoStore((state) => state.deleteMarkerModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClose = () => {      
        handleClose()
    }

    const onConfirm = () => {   
        getServerMgr().deleteMarker({markerId: modalInfo?.id}, () => {
            updateData(onClose)
        })
    }

    return (
        <>
        <Modal 
            show={show} 
            onHide={handleClose}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {"Elimina intervento"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <div>
                        <h6>{"Eliminare il marker " + modalInfo?.address + "?"}</h6>
                    </div>
                }        
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onConfirm}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Cancella"}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}