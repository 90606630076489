import { useRef } from "react";
import GenericButton from "../common/GenericButton";
import { useMapitoStore } from "../zustand/MapitoStore";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DateField } from "@mui/x-date-pickers";

function AppoFilters(){
    const appointmentsTypes = useMapitoStore((state) => state.appointmentTypes)
    const workingStateAppo = useMapitoStore((state) => state.workingStateAppo)
    const appoFilters = useMapitoStore((state) => state.appoFilters)
    const setAppoFiltersAddress = useMapitoStore((state) => state.setAppoFiltersAddress)
    const setAppoFiltersType = useMapitoStore((state) => state.setAppoFiltersType)
    const setAppoFiltersWorkingState = useMapitoStore((state) => state.setAppoFiltersWorkingState)
    const setAppoFiltersDateFrom = useMapitoStore((state) => state.setAppoFiltersDateFrom)
    const setAppoFiltersDateTo = useMapitoStore((state) => state.setAppoFiltersDateTo)
    const setAppoFiltersCompletedFrom = useMapitoStore((state) => state.setAppoFiltersCompletedFrom)
    const setAppoFiltersCompletedTo = useMapitoStore((state) => state.setAppoFiltersCompletedTo)
    const resetAppoFilters = useMapitoStore((state) => state.resetAppoFilters)
    
    const dateFromInputRef = useRef(null);
    const dateToInputRef = useRef(null);


    return(
        <>

                <div style={{marginBottom: "16px"}}>
                    <FormLabel component="legend">Indirizzo</FormLabel>
                    <TextField label="Indirizzo" onChange={(e) => setAppoFiltersAddress(e.target.value)} value={appoFilters.address} sx={{width: "100%"}}/>
                </div>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormLabel component="legend">Tipo interventi</FormLabel>
                    <FormGroup aria-label="position">
                        {appointmentsTypes.map((item) => (
                            <FormControlLabel 
                            sx={{display: "flex", justifyContent: "space-between", width: "100%", height: "24px"}}
                            value={item.name}
                            control={<Checkbox onChange={() => {setAppoFiltersType(Number(item.id), appoFilters.types.includes(Number(item.id)))}} checked={appoFilters.types.includes(Number(item.id))} />}
                            label={item.name}
                            labelPlacement="start"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{width: "90%", marginBottom: "16px"}}>
                    <FormLabel component="legend">Stato del lavoro</FormLabel>
                    <FormGroup aria-label="position">
                        {workingStateAppo.map((item) => (
                            <FormControlLabel 
                            sx={{display: "flex", justifyContent: "space-between", width: "100%", height: "24px"}}
                            value={item.value}
                            control={<Checkbox onChange={() => {setAppoFiltersWorkingState(item.id, appoFilters.workingState.includes(item.id))}} checked={appoFilters.workingState.includes(item.id)} />}
                            label={item.value}
                            labelPlacement="start"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

                <FormLabel component="legend" style={{marginBottom: "15px"}}>Date</FormLabel>
                    {/* <DatePicker label={"dal"} value={markerFilters.dateFrom ? markerFilters.dateFrom : null} max={markerFilters.dateTo} onChange={(e) => {setMarkerFiltersDateFrom(e.target.value)}} />
                    <DatePicker label={"al"} style={{height: "40px"}} value={markerFilters.dateTo ? markerFilters.dateTo : null} min={markerFilters.dateFrom} onChange={(e) => {setMarkerFiltersDateTo(e.target.value)}} /> */}
                <div style={{display: "flex", flexDirection: "column"}}>
                    <TextField label={"dal"} type='date' InputLabelProps={{ shrink: true }} placeholder='Data' InputProps={{inputProps: { max: appoFilters.dateTo} }} value={appoFilters.dateFrom} onChange={(e) => {setAppoFiltersDateFrom(e.target.value)}} style={{marginBottom: "15px"}} sx={{width: "210px"}}/>
                    <TextField label={"al"} type='date' InputLabelProps={{ shrink: true }} placeholder='Orario' InputProps={{inputProps: { min: appoFilters.dateFrom} }} value={appoFilters.dateTo} onChange={(e) => {setAppoFiltersDateTo(e.target.value)}} sx={{width: "210px"}}/>            
                </div>

                <FormLabel component="legend" style={{margin: "15px 0"}}>Date completamento
                </FormLabel>
                    {/* <DatePicker label={"dal"} value={markerFilters.dateFrom ? markerFilters.dateFrom : null} max={markerFilters.dateTo} onChange={(e) => {setMarkerFiltersDateFrom(e.target.value)}} />
                    <DatePicker label={"al"} style={{height: "40px"}} value={markerFilters.dateTo ? markerFilters.dateTo : null} min={markerFilters.dateFrom} onChange={(e) => {setMarkerFiltersDateTo(e.target.value)}} /> */}
                <div style={{display: "flex", flexDirection: "column"}}>
                    <TextField label={"dal"} type='date' InputLabelProps={{ shrink: true }} placeholder='Data' InputProps={{inputProps: { max: appoFilters.completedTo} }} value={appoFilters.completedFrom} onChange={(e) => {setAppoFiltersCompletedFrom(e.target.value)}} style={{marginBottom: "15px"}} sx={{width: "210px"}}/>
                    <TextField label={"al"} type='date' InputLabelProps={{ shrink: true }} placeholder='Orario' InputProps={{inputProps: { min: appoFilters.completedFrom} }} value={appoFilters.completedTo} onChange={(e) => {setAppoFiltersCompletedTo(e.target.value)}} sx={{width: "210px"}}/>            
                    {/* <DateField value={appoFilters.completedTo} min={appoFilters.completedFrom} onChange={(e) => {setAppoFiltersCompletedTo(e.target.value)}}/> */}
                </div>       

                <div style={{height: "48px"}}></div>
                <div style={{position: "sticky", bottom: "20px", width: "100%", display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" startIcon={<RestartAltIcon />} onClick={resetAppoFilters}>
                        Reset filtri
                    </Button>
                </div>

        </>
    );
}

export default AppoFilters;